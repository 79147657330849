import { RequestTracker } from './request-tracker.js';
import getAbsoluteUrl from './url-helpers.js';
function createXmlHttpRequestTracker(xhr, clock, document) {
  const requestTracker = new RequestTracker();
  const trackedRequests = new WeakMap();
  const requestHandlers = new WeakMap();
  const originalOpen = xhr.prototype.open;
  xhr.prototype.open = function open(method, url, ...rest) {
    trackedRequests.set(this, {
      method,
      url: getAbsoluteUrl(String(url), document && document.baseURI)
    });
    // @ts-expect-error rest
    originalOpen.call(this, method, url, ...rest);
  };
  const originalSend = xhr.prototype.send;
  xhr.prototype.send = function send(body) {
    const requestData = trackedRequests.get(this);
    if (requestData) {
      // if there is an existing event listener this request instance is being reused,
      // so we need to remove the listener from the previous send
      const existingHandler = requestHandlers.get(this);
      if (existingHandler) this.removeEventListener('readystatechange', existingHandler);
      const onRequestEnd = requestTracker.start({
        type: 'xmlhttprequest',
        method: requestData.method,
        url: requestData.url,
        startTime: clock.now()
      });
      const onReadyStateChange = evt => {
        if (this.readyState === xhr.DONE && onRequestEnd) {
          // If the status is 0 the request did not complete so report this as an error
          const endContext = this.status > 0 ? {
            endTime: clock.now(),
            status: this.status,
            state: 'success'
          } : {
            endTime: clock.now(),
            state: 'error'
          };
          onRequestEnd(endContext);
        }
      };
      this.addEventListener('readystatechange', onReadyStateChange);
      requestHandlers.set(this, onReadyStateChange);
    }
    originalSend.call(this, body);
  };
  return requestTracker;
}
export { createXmlHttpRequestTracker as default };