import { isNumber } from './validation.js';
class SpanAttributes {
  constructor(initialValues) {
    this.attributes = initialValues;
  }
  set(name, value) {
    if (typeof value === 'string' || typeof value === 'boolean' || isNumber(value)) {
      this.attributes.set(name, value);
    }
  }
  remove(name) {
    this.attributes.delete(name);
  }
  toJson() {
    return Array.from(this.attributes).map(([key, value]) => attributeToJson(key, value));
  }
}
class ResourceAttributes extends SpanAttributes {
  constructor(releaseStage, appVersion, sdkName, sdkVersion) {
    const initialValues = new Map([['deployment.environment', releaseStage], ['telemetry.sdk.name', sdkName], ['telemetry.sdk.version', sdkVersion]]);
    if (appVersion.length > 0) {
      initialValues.set('service.version', appVersion);
    }
    super(initialValues);
  }
}
function attributeToJson(key, attribute) {
  switch (typeof attribute) {
    case 'number':
      if (Number.isNaN(attribute) || !Number.isFinite(attribute)) {
        return undefined;
      }
      // 'bugsnag.sampling.p' must always be sent as a doubleValue
      if (key !== 'bugsnag.sampling.p' && Number.isInteger(attribute)) {
        return {
          key,
          value: {
            intValue: `${attribute}`
          }
        };
      }
      return {
        key,
        value: {
          doubleValue: attribute
        }
      };
    case 'boolean':
      return {
        key,
        value: {
          boolValue: attribute
        }
      };
    case 'string':
      return {
        key,
        value: {
          stringValue: attribute
        }
      };
    default:
      return undefined;
  }
}
export { ResourceAttributes, SpanAttributes, attributeToJson };