function spanContextEquals(span1, span2) {
  if (span1 === span2) return true;
  if (span1 !== undefined && span2 !== undefined) {
    return span1.id === span2.id && span1.traceId === span2.traceId;
  }
  return false;
}
class DefaultSpanContextStorage {
  constructor(backgroundingListener, contextStack = []) {
    this.isInForeground = true;
    this.onBackgroundStateChange = state => {
      this.isInForeground = state === 'in-foreground';
      // clear the context stack regardless of the new background state
      // since spans are only valid if they start and end while the app is in the foreground
      this.contextStack.length = 0;
    };
    this.contextStack = contextStack;
    backgroundingListener.onStateChange(this.onBackgroundStateChange);
  }
  *[Symbol.iterator]() {
    for (let i = this.contextStack.length - 1; i >= 0; --i) {
      yield this.contextStack[i];
    }
  }
  push(context) {
    if (context.isValid() && this.isInForeground) {
      this.contextStack.push(context);
    }
  }
  pop(context) {
    if (spanContextEquals(context, this.current)) {
      this.contextStack.pop();
    }
    this.removeClosedContexts();
  }
  get first() {
    this.removeClosedContexts();
    return this.contextStack.length > 0 ? this.contextStack[0] : undefined;
  }
  get current() {
    this.removeClosedContexts();
    return this.contextStack.length > 0 ? this.contextStack[this.contextStack.length - 1] : undefined;
  }
  removeClosedContexts() {
    while (this.contextStack.length > 0 && this.contextStack[this.contextStack.length - 1].isValid() === false) {
      this.contextStack.pop();
    }
  }
}
export { DefaultSpanContextStorage, spanContextEquals };