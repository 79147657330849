const isBoolean = value => value === true || value === false;
const isObject = value => !!value && typeof value === 'object' && !Array.isArray(value);
const isNumber = value => typeof value === 'number' && Number.isFinite(value) && !Number.isNaN(value);
const isString = value => typeof value === 'string';
const isStringWithLength = value => isString(value) && value.length > 0;
const isLogger = value => isObject(value) && typeof value.debug === 'function' && typeof value.info === 'function' && typeof value.warn === 'function' && typeof value.error === 'function';
const isStringArray = value => Array.isArray(value) && value.every(isStringWithLength);
const isStringOrRegExpArray = value => Array.isArray(value) && value.every(item => isStringWithLength(item) || item instanceof RegExp);
function isPersistedProbability(value) {
  return isObject(value) && isNumber(value.value) && isNumber(value.time);
}
const isSpanContext = value => isObject(value) && typeof value.id === 'string' && typeof value.traceId === 'string' && typeof value.isValid === 'function';
function isTime(value) {
  return isNumber(value) || value instanceof Date;
}
// NOTE: this should be kept in sync with the notifier
// https://github.com/bugsnag/bugsnag-js/blob/next/packages/plugin-browser-device/device.js
function isDeviceId(value) {
  // make sure the persisted value looks like a valid cuid
  return typeof value === 'string' && /^c[a-z0-9]{20,32}$/.test(value);
}
export { isBoolean, isDeviceId, isLogger, isNumber, isObject, isPersistedProbability, isSpanContext, isString, isStringArray, isStringOrRegExpArray, isStringWithLength, isTime };