import DomMutationSettler from './dom-mutation-settler.js';
import LoadEventEndSettler from './load-event-end-settler.js';
import RequestSettler from './request-settler.js';
import SettlerAggregate from './settler-aggregate.js';
const TIMEOUT_MILLISECONDS = 60 * 1000;
function createOnSettle(clock, window, fetchRequestTracker, xhrRequestTracker, performance) {
  const domMutationSettler = new DomMutationSettler(clock, window.document);
  const fetchRequestSettler = new RequestSettler(clock, fetchRequestTracker);
  const xhrRequestSettler = new RequestSettler(clock, xhrRequestTracker);
  const loadEventEndSettler = new LoadEventEndSettler(clock, window.addEventListener, performance, window.document);
  const settler = new SettlerAggregate(clock, [domMutationSettler, loadEventEndSettler, fetchRequestSettler, xhrRequestSettler]);
  function onSettlePlugin(callback) {
    const onSettle = settledTime => {
      clearTimeout(timeout);
      // unsubscribe from the settler so we don't call the callback more than
      // once
      settler.unsubscribe(onSettle);
      callback(settledTime);
    };
    const timeout = setTimeout(() => {
      const settledTime = clock.now();
      settler.unsubscribe(onSettle);
      callback(settledTime);
    }, TIMEOUT_MILLISECONDS);
    // if we're already settled apply a 100ms "cooldown" period in case we
    // unsettle immediately after this call
    // if we're not settled then this cooldown is irrelevant - we can just
    // subscribe to the settler to be notified of when the page settles
    const cooldown = settler.isSettled() ? 100 : 0;
    const settledTime = clock.now();
    setTimeout(() => {
      if (settler.isSettled()) {
        // if we're still settled call the callback via "onSettle"
        onSettle(settledTime);
      } else {
        // otherwise wait for the page to settle
        settler.subscribe(onSettle);
      }
    }, cooldown);
  }
  onSettlePlugin.configure = function (configuration) {
    const settleIgnoreUrls = configuration.settleIgnoreUrls.map(url => typeof url === 'string' ? RegExp(url) : url).concat(RegExp(configuration.endpoint));
    fetchRequestSettler.setUrlsToIgnore(settleIgnoreUrls);
    xhrRequestSettler.setUrlsToIgnore(settleIgnoreUrls);
  };
  return onSettlePlugin;
}
export { createOnSettle as default };