import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './common/auth.guard';
import { FullscreenLayoutComponent } from './layouts/fullscreen-layout/fullscreen-layout.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'kiosks',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        path: '',
        component: FullscreenLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('src/app/pages/manager/manager.module').then(m => m.ManagerModule)
            },
        ]
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('src/app/pages/auth/auth.module').then(m => m.AuthModule)
            }
        ]
    },
    {
        path: '**',
        redirectTo: 'kiosks'
    }
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes, {
            useHash: false,
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            onSameUrlNavigation: 'reload',
            scrollOffset: [0, 64]
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
