import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { DataService } from '../services/data.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private dataService: DataService) {
    }

    canActivate() {
        if (this.dataService.getProvisioning()?.kiosks?.length && this.dataService.getUserName()?.length) {
            return true;
        }

        this.router.navigate(['/auth']).then()
        return false;
    }
}

