import { BatchProcessor } from './batch-processor.js';
import { validateConfig } from './config.js';
import { TracePayloadEncoder } from './delivery.js';
import ProbabilityFetcher from './probability-fetcher.js';
import ProbabilityManager from './probability-manager.js';
import { BufferingProcessor } from './processor.js';
import Sampler from './sampler.js';
import { DefaultSpanContextStorage } from './span-context.js';
import { SpanFactory } from './span-factory.js';
function createClient(options) {
  const bufferingProcessor = new BufferingProcessor();
  let processor = bufferingProcessor;
  const spanContextStorage = options.spanContextStorage || new DefaultSpanContextStorage(options.backgroundingListener);
  let logger = options.schema.logger.defaultValue;
  const sampler = new Sampler(1.0);
  const spanFactory = new SpanFactory(processor, sampler, options.idGenerator, options.spanAttributesSource, options.clock, options.backgroundingListener, logger, spanContextStorage);
  const plugins = options.plugins(spanFactory, spanContextStorage);
  return Object.assign({
    start: config => {
      const configuration = validateConfig(config, options.schema);
      const delivery = options.deliveryFactory(configuration.endpoint);
      options.spanAttributesSource.configure(configuration);
      ProbabilityManager.create(options.persistence, sampler, new ProbabilityFetcher(delivery, configuration.apiKey)).then(manager => {
        processor = new BatchProcessor(delivery, configuration, options.retryQueueFactory(delivery, configuration.retryQueueMaxSize), sampler, manager, new TracePayloadEncoder(options.clock, configuration, options.resourceAttributesSource));
        // ensure all spans started before .start() are added to the batch
        for (const span of bufferingProcessor.spans) {
          processor.add(span);
        }
        // register with the backgrounding listener - we do this in 'start' as
        // there's nothing to do if we're backgrounded before start is called
        // e.g. we can't trigger delivery until we have the apiKey and endpoint
        // from configuration
        options.backgroundingListener.onStateChange(state => {
          processor.flush();
        });
        logger = configuration.logger;
        spanFactory.configure(processor, logger);
      });
      for (const plugin of plugins) {
        plugin.configure(configuration);
      }
    },
    startSpan: (name, spanOptions) => {
      const cleanOptions = spanFactory.validateSpanOptions(name, spanOptions);
      const span = spanFactory.startSpan(cleanOptions.name, cleanOptions.options);
      span.setAttribute('bugsnag.span.category', 'custom');
      return spanFactory.toPublicApi(span);
    },
    get currentSpanContext() {
      return spanContextStorage.current;
    }
  }, options.platformExtensions && options.platformExtensions(spanFactory, spanContextStorage));
}
function createNoopClient() {
  const noop = () => {};
  return {
    start: noop,
    startSpan: () => ({
      id: '',
      traceId: '',
      end: noop,
      isValid: () => false
    }),
    currentSpanContext: undefined
  };
}
export { createClient, createNoopClient };