import { getAbsoluteUrl } from '@bugsnag/request-tracker-performance';
const defaultRouteResolver = url => url.pathname || '/';
const createDefaultRoutingProvider = (onSettle, location) => {
  return class DefaultRoutingProvider {
    constructor(resolveRoute = defaultRouteResolver) {
      this.resolveRoute = resolveRoute;
    }
    listenForRouteChanges(startRouteChangeSpan) {
      addEventListener('popstate', ev => {
        const url = new URL(location.href);
        const span = startRouteChangeSpan(url, 'popstate');
        onSettle(endTime => {
          span.end(endTime);
        });
      });
      const originalPushState = history.pushState;
      history.pushState = function (...args) {
        const url = args[2];
        if (url) {
          const absoluteURL = new URL(getAbsoluteUrl(url.toString(), document.baseURI));
          const span = startRouteChangeSpan(absoluteURL, 'pushState');
          onSettle(endTime => {
            span.end(endTime);
          });
        }
        originalPushState.apply(this, args);
      };
    }
  };
};
export { createDefaultRoutingProvider, defaultRouteResolver };